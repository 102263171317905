@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.asideitem {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  color: red;
}

.py-4 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.px-3 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.test {
  overflow-x: clip;
}

.Card {
  margin-bottom: 35px !important;
}
.hide-scroll-bar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.hide-scroll-bar::-webkit-scrollbar {
  display: none;
}

.crd {
  width: 30em;
}

.shortener {
  width: fit-content;
  margin: 0 auto;
  text-align: center;
}

.shortener input {
  border: 1px solid gray;
  color: rgb(64, 64, 66);
}
.shortener input:focus {
  border: 1px solid rgb(95, 180, 245);
  outline: none;
}

.linkbutton {
  width: 7rem;
}

.shortener__viewShot {
  margin-top: 20px;
  border: 1px solid gainsboro;
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
  color: rgb(64, 64, 66);
  align-items: center;
}
.shortener__viewShot button {
  background-color: transparent;
  border: none;
  color: gray;
}
.shortener__viewShot button:hover {
  color: rgb(64, 64, 66);
}
